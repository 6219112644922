exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-not-authorised-tsx": () => import("./../../../src/pages/not-authorised.tsx" /* webpackChunkName: "component---src-pages-not-authorised-tsx" */),
  "component---src-pages-other-album-tsx": () => import("./../../../src/pages/other/album.tsx" /* webpackChunkName: "component---src-pages-other-album-tsx" */),
  "component---src-pages-posts-all-posts-tsx": () => import("./../../../src/pages/posts/all-posts.tsx" /* webpackChunkName: "component---src-pages-posts-all-posts-tsx" */),
  "component---src-pages-posts-my-posts-tsx": () => import("./../../../src/pages/posts/my-posts.tsx" /* webpackChunkName: "component---src-pages-posts-my-posts-tsx" */),
  "component---src-pages-posts-new-post-tsx": () => import("./../../../src/pages/posts/new-post.tsx" /* webpackChunkName: "component---src-pages-posts-new-post-tsx" */),
  "component---src-pages-posts-post-id-tsx": () => import("./../../../src/pages/posts/[postId].tsx" /* webpackChunkName: "component---src-pages-posts-post-id-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-password-change-tsx": () => import("./../../../src/pages/register/password-change.tsx" /* webpackChunkName: "component---src-pages-register-password-change-tsx" */)
}

